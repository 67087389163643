<template>
    <v-chart class="chart" :option="option" />
</template>
  
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { max } from "zrender/lib/core/vector";
import { rotate } from "zrender/lib/core/matrix";

use([
    CanvasRenderer,
    LineChart,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent
]);

export default {
    props: ['remarks','setPoint','supplyPoint','maxTemp','minTemp','returnPoint','O2','actualO2','probe1','probe2','probe3','cO2','actualCO2','timeline'],
    components: {
        VChart
    },
    provide: {
        // [THEME_KEY]: "dark"
    },
    mounted() {
        console.log(this.maxTemp);
    },
    data() {
        return {
            option: {
                title: {
                    text: 'Port Monitoring Temperature',
                        left: 'center',
                        top: 20,
                        textStyle: {
                            // color: '#333',
                            fontSize: 14
                        }
                },
                tooltip: {
                    trigger: 'axis'
                },
                // legend: {
                //     data: ['DAT', 'RAT'],
                //     textStyle: {
                //         color: "white"
                //     }
                // },
                toolbox: {
                    show: true,
                    feature: {
                        magicType: { type: ['line', 'bar'] },
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.timeline,
                    axisLabel: {
                        color: "white",
                        margin: 5,
                        hideOverlap: true
                    },
                    name: 'Date',
                    nameTextStyle: {
                        color: 'white',
                        padding: 20,
                        fontSize: 15
                    },
                    nameLocation: 'middle',
                    nameRotate: 0,
                    nameAlign: 'center',                    
                },
                yAxis: {
                    type: 'value',
                    // max: Math.round((Math.round(this.settings.maxTemp) + 25) / 5) * 5,
                    // min: Math.round((Math.round(this.settings.minTemp) - 10) / 5) * 5,
                    axisLabel: {
                        formatter: '{value} °C',
                        color: "white",
                        margin: 5,
                        hideOverlap: true

                    },
                    name: 'Temperature',
                    nameTextStyle: {
                        color: 'white',
                        padding: 30,
                        fontSize: 15

                    },
                    nameLocation: 'middle',
                    nameGap: 30,
                    nameRotate: 90,
                    nameAlign: 'center',
                },
                series: [
                    {
                        name: 'Set Point',
                        type: 'line',
                        color: 'lightGreen',
                        data: this.setPoint,  
                        // markPoint:{
                        //     data:[
                        //     {
                        //         coord: [this.setPoint, this.remarks],
                        //         value: this.remarks,
                        //         label: {
                        //             position: "inside",
                        //             formatter: function (params) {
                        //             return params.value;
                        //             }
                        //         },
                        //         itemStyle: {
                        //             color: "red"
                        //         }
                        //     }
                        //     ]
                        // }
                    },    
                    {
                        name: 'Supply Point',
                        type: 'line',
                        color: 'lightBlue',
                        data: this.supplyPoint,
                    },
                    {
                        name: 'Return Point',
                        type: 'line',
                        color: 'lightYellow',
                        data: this.returnPoint,
                    },
                    {
                        name: 'O2',
                        type: 'line',
                        color: 'lightPink',
                        data: this.O2,
                    },
                    {
                        name: 'Actual O2',
                        type: 'line',
                        color: 'lightGrey',
                        data: this.actualO2,
                    },
                    {
                        name: 'Probe 1',
                        type: 'line',
                        color: 'lightCoral',
                        data: this.probe1,
                    },
                    {
                        name: 'Probe 2',
                        type: 'line',
                        color: 'lightSalmon',
                        data: this.probe2,
                    },
                    {
                        name: 'Probe 3',
                        type: 'line',
                        color: 'lightSeaGreen',
                        data: this.probe3,
                    },
                    {
                        name: 'CO2',
                        type: 'line',
                        color: 'lightSkyBlue',
                        data: this.cO2,
                    },
                    {
                        name: 'Actual CO2',
                        type: 'line',
                        color: 'lightSlateGrey',
                        data: this.actualCO2,
                    },

                      {
                        name: 'Max',
                        type: 'line',
                        color: 'red',
                        data: Array(this.setPoint.length).fill(this.maxTemp),
                        showInLegend: false,
                        enableMouseTracking: false,
                        lineStyle: {
                            type: 'dashed'
                        },
                    },
                    {
                        name: 'Min',
                        type: 'line',
                        color: 'blue',
                        data: Array(this.setPoint.length).fill(this.minTemp),
                        showInLegend: false,
                        enableMouseTracking: false,
                        lineStyle: {
                            type: 'dashed'
                        },
                    }
                ]
            }
        };
    }
};
</script>
  
<style scoped>
.chart {
    height: 65vh;
}
</style>